<template>
  <div class="container">
    <div class="flex-center min-vh-100 py-6 row">
      <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5">
        <a class="text-decoration-none" href="/">
          <div class="d-flex flex-center flex-column mb-4 text-brand">
              <img width="140" src="@/assets/images/logo/logo-le-bon-panier-black.svg" alt="Le bon panier.ca" class="mb-5">
            <span class="text-sans-serif">Ouvrir une session</span>
          </div>
        </a>

        <div class="card">
          <div class="card-body p-5">
            <div class="heading mb-4">
              <h5>Connexion</h5>
            </div>

            <div class="alert alert-danger" v-if="hasError">
              <div v-if="errorType === 'userNotFound' || errorType === 'wrongPassword' || errorType === 'forbidden'">Veuillez vérifier votre email ou votre mot de passe.</div>
              <div v-else>Une erreur interne est survenu.</div>
            </div>

            <form @submit.prevent="submit">
              <div class="form-group">
                <input type="email" class="form-control" placeholder="Adresse e-mail" v-model="form.username">
              </div>

              <div class="form-group">
                <input type="password" class="form-control" placeholder="Mot de passe" v-model="form.password">
              </div>

              <div class="form-group">
                <button class="btn btn-block btn-primary" type="Connexion" :disabled="isSubmitDisabled">Connexion</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        username: '',
        password: ''
      },
      isProcessing: false,
      hasError: false,
      errorType: null
    }
  },
  computed: {
    isSubmitDisabled () {
      return this.form.username.length === 0 || this.form.password.length === 0 || this.isProcessing
    }
  },
  methods: {
    async submit () {
      this.isProcessing = true
      this.hasError = false

      try {
        await this.$store.dispatch('login', this.form)
        this.isProcessing = false
        this.$router.push('/')
      } catch (error) {
        this.isProcessing = false
        this.hasError = true

        if (error.code === 'auth/user-not-found') {
          this.errorType = 'userNotFound'
        } else if (error.code === 'auth/wrong-password') {
          this.errorType = 'wrongPassword'
        } else if (error.response && (error.response.status === 403 || error.response.status === 404)) {
          this.errorType = 'forbidden'
        } else if (error.message === 'forbidden') {
          this.errorType = 'forbidden'
        } else {
          this.errorType = 'internal'
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.text-brand {
  font-size: 32px;
  font-weight: 800;
}
</style>
